import React, { useRef } from 'react';

interface DestinationSelectorProps {
  selectedCountry: string;
  dropdownOpen: boolean;
  inputValue: string;
  handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleItemClick: (item: string) => void;
  handleContainerClick: () => void;
}

const DestinationSelector: React.FC<DestinationSelectorProps> = ({
  selectedCountry,
  dropdownOpen,
  inputValue,
  handleInput,
  handleItemClick,
  handleContainerClick,
}) => {
  const valueArray: string[] = ['Thailand', 'Mexico', 'Costa Rica', 'Indonesia', 'Japan'];
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <form style={{ color: 'black'}}>
      <div className="form-container" onClick={handleContainerClick} ref={containerRef}>
        <input
          className="chosen-value"
          type="text"
          value={inputValue}
          placeholder="Type to filter"
          onInput={handleInput}
        />
        <ul className={`value-list ${dropdownOpen ? 'open' : ''}`} id="value-list">
          {valueArray.map((item) => (
            <li
              key={item}
              onClick={() => handleItemClick(item)}
              className={item.toLowerCase().startsWith(inputValue) ? '' : 'closed'}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </form>
  );
};

export default DestinationSelector;
