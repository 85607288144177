import React, { useState } from 'react';
import './App.css';
import { Col, Container, Row } from 'react-bootstrap';
import MockPhone from './components/MockPhone/MockPhone';

interface AppProps { }

const App: React.FC<AppProps> = () => {

  const [selectedCountry, setSelectedCountry] = useState<string>('');

  const handleCountrySelect = (country: string) => {
    setSelectedCountry(country);
  };

  const renderSelectCountryHeading = () => {
    return 'Select Destination'
  }

  const renderAppsForCountryHeading = (selectedCountry: string) => {
    return `All the apps you need for your next trip to ${selectedCountry}!`
  }

  return (

    <Container fluid className='App'>
      <Row><h1 className='AppTitle'>Global App Database</h1></Row>
      <Row>
        <Col>

          <h2 style={{ marginTop: '3rem', fontSize: '1.25rem' }}>
            {
              selectedCountry === '' ?
                renderSelectCountryHeading() : renderAppsForCountryHeading(selectedCountry)
            }
          </h2>

        </Col>
      </Row>
      <Row>
        <Col>
          <MockPhone selectedCountry={selectedCountry} onCountrySelect={handleCountrySelect} />
        </Col>
      </Row>


    </Container>

  );
};

export default App;
