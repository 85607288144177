// Import necessary components and libraries
import React, { useState } from 'react';
import './MockPhone.scss';
import DestinationSelector from '../DestinationSelector/DestinationSelector';
import AppGrid from '../AppGrid/AppGrid';
import { Col, Container, Row } from 'react-bootstrap';

interface MockPhoneProps {
  selectedCountry: string;
  onCountrySelect: (country: string) => void;
}

const MockPhone: React.FC<MockPhoneProps> = ({ selectedCountry, onCountrySelect }) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase();
    setInputValue(inputValue);
    setDropdownOpen(true);
  };

  const handleItemClick = (item: string) => {
    setInputValue(item);
    onCountrySelect(item);
    setDropdownOpen(false);
  };

  const handleContainerClick = () => {
    if (selectedCountry) {
      onCountrySelect(''); // Call the onCountrySelect function to update the selectedCountry in the parent component
      setInputValue('');
    }
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="iphone-x">
      <i>Speaker</i>
      <b>Camera</b>
      <Container>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <DestinationSelector
              selectedCountry={selectedCountry}
              dropdownOpen={dropdownOpen}
              inputValue={inputValue}
              handleInput={handleInput}
              handleItemClick={handleItemClick}
              handleContainerClick={handleContainerClick}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {selectedCountry && (
              <div className="apps-list-container">
                <AppGrid selectedCountry={selectedCountry} />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MockPhone;
