import React, { useEffect, useState } from 'react';
import { App } from '../../types';
import './AppGrid.css';
import { Col, Container, Row } from 'react-bootstrap';
import GridItem from '../GridItem/GridItem';

const NEW_TAB = "_blank";

interface AppGridProps {
  selectedCountry: string;
}

const onClickGridItem = (app: App): void => {
  if (/Android/i.test(navigator.userAgent)) {
    window.open(app.androidApp || app.url, NEW_TAB);
  } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    window.open(app.iosApp || app.url, NEW_TAB);
  } else {
    window.open(app.url, NEW_TAB);
  }
};


const AppGrid: React.FC<AppGridProps> = ({ selectedCountry }) => {
  const [apps, setApps] = useState<{ [category: string]: App[] }>({});

  useEffect(() => {

    const apiKey = process.env.REACT_APP_API_KEY || '';

    const headers = {
      'x-api-key': apiKey
    }

    const fetchData = async () => {
      try {
        const countryResponse = await fetch(
          `/.netlify/functions/get${selectedCountry.replace(/\s/g, '')}`,
          {
            method: 'GET',
            headers: headers
          });
        if (countryResponse.ok) {
          const countryData = await countryResponse.json();
          setApps(countryData.apps);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedCountry]);

  

  return (
    <Container>
      <Row>
        {Object.entries(apps).map(([category, appsInCategory]) => (
          <Col xs={12} key={category}>
            <div className='grid-container app-group'>
              {Object.values(appsInCategory).map((app) => (
                
                <GridItem app={app} onClick={onClickGridItem}></GridItem>
                
              ))}
            </div>
            <h5 style={{ fontSize: '10px', textTransform: 'uppercase' }}>
              {category.replace("_", " ")}
            </h5>
          </Col>
        ))}
      </Row>
    </Container>

  );
};

export default AppGrid;
