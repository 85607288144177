// GridItem.tsx

import React from 'react';
import { App } from '../../types';
import './GridItem.css';

interface AppIconProps {
  app: App;
  onClick: (app: App) => void;
}

const GridItem: React.FC<AppIconProps> = ({ app, onClick }) => {

  return (

    <div className="grid-item" onClick={() => onClick(app)} >
      <img
        src={`/images/${app.icon}`}
        alt={app.name}
        className="app-icon"
        onClick={() => onClick(app)}
      />
      <h5 className='grid-item-name'>{app.name}</h5>
    </div>

  );
}

export default GridItem;
